import Sidebar from '../sidebar/sidebar';
import Header from '../header/header';
import JoinedClasses from './joinedClasses';
const YourClasses = () => {
 


    

    return (<div className='bg-[#E1EAE8] h-[100vh]'>

        <Sidebar/>
        <Header/>
        <JoinedClasses/>
   

        
      
       
       
        
        </div>
        
        
   
    );
};

export default YourClasses;
